/* eslint-disable react/require-default-props */
import React from 'react';
import dynamic from 'next/dynamic';
import styled, { FlattenInterpolation, ThemeProps, css } from 'styled-components';

const Dots: any = dynamic(() => import('react-carousel-dots'), { ssr: false });

type StyledCustomDotsProps = {
  length: number;
  customDotStyles?: FlattenInterpolation<ThemeProps<any>>;
};

const StyledCustomDots = styled.div<StyledCustomDotsProps>`
  position: absolute;
  bottom: 26px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
  width: 100%;

  .dot-holder {
    width: unset !important;
    min-width: 7px !important;
  }

  .react-carousel-dots-dot {
    border: none;
    background-color: #dfdfdf;
  }

  .react-carousel-dots-dot.active {
    background-color: ${(props) => props.theme.color.primaryBase};
    border-radius: 20px;
    width: 16px;
  }

  .react-carousel-dots-holder {
    width: 86px !important;

    ${(props) =>
      props.length === 2 &&
      css`
        width: 44px !important;
      `}
    ${(props) =>
      props.length === 3 &&
      css`
        width: 56px !important;
      `}
  }

  .react-carousel-dots-dot.small {
    transform: initial;
  }

  ${(props) => props.customDotStyles};
`;

interface CustomSliderDotsProps {
  length: number;
  activeIndex: number;
  visible?: number;
  customDotStyles?: FlattenInterpolation<ThemeProps<any>>;
}

const CustomSliderDots = ({ length, activeIndex, visible, customDotStyles }: CustomSliderDotsProps) => {
  if (length < 2) return null;

  return (
    <StyledCustomDots customDotStyles={customDotStyles} length={length}>
      <Dots
        length={length || 0}
        active={activeIndex}
        visible={visible || length > 4 ? 4 : length}
        size={7}
        margin={4}
      />
    </StyledCustomDots>
  );
};

export default CustomSliderDots;
