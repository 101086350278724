/* eslint-disable react/default-props-match-prop-types */
import React, { FC } from 'react';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';

import type { ButtonProps } from '@design-system/components/Button/ButtonProps';

const StyledButton = styled(AntButton)<ButtonProps>`
  ${(props) =>
    props.size === 'large' ? props.theme.typo.style.mainButtonOrLink : props.theme.typo.style.smallButtonOrLink}

  align-items: center;
  background-color: ${(props) =>
    props.variant === 'contained' ? props.theme.color.primaryBase : props.theme.color.background};
  border-radius: 12px;
  border: 1px solid
    ${(props) => (props.variant === 'text' ? props.theme.color.background : props.theme.color.primaryBase)};
  color: ${(props) => (props.variant === 'contained' ? props.theme.color.background : props.theme.color.primaryBase)};
  display: flex;
  height: ${(props) => (props.size === 'large' ? '42px' : '30px')};
  justify-content: center;
  padding-inline: ${(props) => (props.size === 'large' ? '16px' : '10px')};
  text-shadow: none;
  box-shadow: ${(props) => (props.type === 'primary' ? props.theme.color.boxShadow.primaryFocus : 'none')};

  &:hover {
    background-color: ${(props) =>
      props.variant === 'contained' ? props.theme.color.primaryHover : props.theme.color.background};
    border: 1px solid
      ${(props) => (props.variant === 'text' ? props.theme.color.background : props.theme.color.primaryHover)};
    box-shadow: ${(props) => (props.variant === 'contained' ? props.theme.color.boxShadow.primaryHover : 'none')};
    color: ${(props) =>
      props.variant === 'contained' ? props.theme.color.background : props.theme.color.primaryHover};
  }

  &:focus {
    background-color: ${(props) =>
      props.variant === 'contained' ? props.theme.color.primaryBase : props.theme.color.background};
    border: 1px solid
      ${(props) => (props.variant === 'text' ? props.theme.color.background : props.theme.color.primaryHover)};
    box-shadow: ${(props) => props.theme.color.boxShadow.primaryFocus};
    color: ${(props) => (props.variant === 'contained' ? props.theme.color.background : props.theme.color.primaryBase)};
  }

  &:disabled {
    background-color: ${(props) =>
      props.variant === 'contained' ? props.theme.color.onBackgroundLowEmphasis : props.theme.color.background};
    border: 1px solid
      ${(props) =>
        props.variant === 'text' ? props.theme.color.background : props.theme.color.onBackgroundLowEmphasis};
    color: ${(props) =>
      props.variant === 'contained' ? props.theme.color.background : props.theme.color.onBackgroundLowEmphasis};
  }
`;

const Button: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  size,
  variant,
  href,
  block,
  loading,
  type,
  onClick,
  target,
  htmlType,
}) => (
  <StyledButton
    className={className}
    size={size}
    variant={variant}
    disabled={disabled}
    href={href}
    block={block}
    loading={loading}
    type={type}
    target={target}
    onClick={onClick}
    htmlType={htmlType}
  >
    {children}
  </StyledButton>
);

Button.defaultProps = {
  size: 'large',
  variant: 'contained',
  disabled: false,
  block: false,
};

export default Button;
