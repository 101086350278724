import React, { useState, ReactNode, FC, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 30px;
  }
`;

const iconStyle = {
  color: '#878787',
  margin: '0 4px',
  fontSize: '16px',
};

const closeIconStyle = {
  fontSize: '24px',
  color: 'black',
  padding: '10px',
  marginTop: '10px',
  marginRight: '10px',
};

type Props = {
  content: () => ReactNode;
} & PropsWithChildren;

const PriceInfoModal: FC<Props> = ({ content, children }) => {
  const [showPriceInfoModal, setShowPriceInfo] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onClick={handleClick} aria-hidden="true" style={{ lineHeight: '1' }}>
      {children ? (
        <div aria-hidden onClick={() => setShowPriceInfo(true)}>
          {children}
        </div>
      ) : (
        <InfoCircleOutlined style={iconStyle} onClick={() => setShowPriceInfo(true)} />
      )}
      <StyledModal
        visible={showPriceInfoModal}
        maskClosable
        closable={false}
        onCancel={() => setShowPriceInfo(false)}
        footer={null}
        width={370}
        centered
        bodyStyle={{ padding: 0, margin: 0 }}
        closeIcon={<CloseCircleOutlined style={closeIconStyle} />}
      >
        {content()}
      </StyledModal>
    </div>
  );
};

export default memo(PriceInfoModal);
