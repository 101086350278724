import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import styled, { FlattenInterpolation, ThemeProps } from 'styled-components';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import PhotoCarousel, { PhotoCarouselProps } from '@design-system/components/BrowsingCard/PhotoCarousel';
import { Typography } from '@design-system/components/Typography';
import useMobileView from '@design-system/hooks/useMobileView';

import PriceInfoContent from '@design-system/components/BrowsingCard/PriceInfoContent';
import PriceInfoModal from '@design-system/components/BrowsingCard/PriceInfoModal';
import getBrowsingCardConfigs, { NEW_ARRIVAL_TAG } from '@design-system/components/BrowsingCard/configs';
import { CountryCode, formatCarInfo, mappingCountryCurrency } from '@design-system/components/BrowsingCard/utils';
import useRegionConfig from '@source/hooks/useRegionConfig';
import PromoLabel from '@design-system/components/BrowsingCard/PromoLabel';
import FeatureBadge from '@design-system/components/BrowsingCard/FeatureBadge';
import CarroCertifiedPopoverContent from '@design-system/components/BrowsingCard/CarroCertifiedPopoverContent';
import StatusRibbon from '@design-system/components/BrowsingCard/StatusRibbon';
import { getStaticCDN } from '@design-system/utils/utils';
import TotalPaymentPopoverContent from '@design-system/components/BrowsingCard/TotalPaymentPopoverContent';
import { IMAGE_ASPECT_RATIO } from '@design-system/constants';
import AllInPrice from '@source/components/AllInPrice/AllInPrice';
import CarroCertifiedLitePopoverContent from '@design-system/components/BrowsingCard/CarroCertifiedLitePopoverContent';

export interface BrowsingCardProps extends PhotoCarouselProps {
  car: object;
  region: string;
  href: string;
  // Disabled because no need to set default value
  // eslint-disable-next-line
  wrapperStyles?: FlattenInterpolation<ThemeProps<any>>;
  isExternal?: boolean;
  onOtherOnClickAction?: (params: any) => void;
  language: string;
}

type StyledWrapperProps = {
  wrapperStyles?: FlattenInterpolation<ThemeProps<any>>;
};

interface ISecondaryDetailsMY {
  isGrid?: boolean;
}

const StyledPhotoWrapper = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  .photo-carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const StyledWrapper = styled.a<StyledWrapperProps>`
  display: inline-block;
  position: relative;
  box-shadow: ${(props) => props.theme.color.boxShadow.cardRaiseMiddleShadow};
  user-select: none;
  margin: 0 auto;
  color: initial;
  ${(props) => props.theme.typo.familyGoogle.regular};

  &:focus,
  &:hover {
    color: inherit;
  }

  .browsing-card-info-icon {
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    margin-left: 5px;
  }

  .eclipse {
    position: relative;
    display: flex;
    align-items: center;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      right: -12px;
      width: 4px;
      height: 4px;
      background-color: black;
      border-radius: 100%;
      top: calc(50% - 3px);
    }
  }
  border: 1px solid ${(props) => props.theme.color.border}; // #ededed; // set color in theme later
  box-shadow: 0px 4px 15px ${(props) => props.theme.color.borderShadow}; // rgba(33, 33, 33, 0.15); // set color in theme later
  border-radius: 12px;
  width: 100%;
  height: 100%;

  .lazy-load-image-loaded {
    img {
      /* border-top-right-radius: 12px !important;
      border-top-left-radius: 12px !important; */
    }
  }

  ${(props) => props.wrapperStyles}
`;

const StyledTag = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);

  width: 90px;
  @media (min-width: 340px) {
    width: 95px;
  }
`;

const StyledCardContentWrapper = styled.div<{ region: string }>`
  text-align: left;
  padding: 8px 16px 16px 16px;
  border-top: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;

  .browsing-card-remaining-date {
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    margin-left: 4px;
  }

  .browsing-card-tags {
    margin-bottom: 8px;
    display: flex;
    gap: 10px;
  }

  .browsing-card-title {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    ${(props) => props.theme.typo.style.captionBold};
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #3b3b3b;
    font-size: 14px;
    line-height: 22px;
  }

  .browsing-card-price {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.primaryBase};
  }

  .th {
    .browsing-card-currency {
      white-space: pre-wrap;

      &:last-child {
        font-size: 12px !important;
      }
    }
  }
  .browsing-card-currency {
    ${(props) => props.theme.typo.familyGoogle.bold};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .browsing-card-price-details {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
  }

  .priceWithGst {
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
  }

  .priceWithGstTH {
    color: ${(props) => props.theme.color.lightGray};
  }

  .finalPrice {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};

    .gst {
      font-family: ${(props) => props.theme.typo.familyGoogle.regular};
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
  }

  .browsing-card-secondary-details {
    display: flex;
    align-items: center;
    &.th {
      .browsing-card-instalment {
        cursor: pointer;
      }
    }
  }

  .browsing-card-original-price {
    text-decoration: line-through;
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    top: 4px;
  }

  .browsing-card-original-price-th {
    font-size: 9px;
    line-height: 20px;
    ${(props) => props.theme.typo.familyGoogle.regular};
  }

  .browsing-card-discount {
    background-color: ${(props) => props.theme.color.background};
    color: ${(props) => props.theme.color.primaryBase};
    border: 1px solid ${(props) => props.theme.color.primaryBase};
    padding: 2px 4px;
    border-radius: 4px;
    border-left: 0;
    position: relative;
    margin-left: 10px !important;
    height: 20px;
    transform-style: preserve-3d;

    &::after {
      content: ' ';
      height: 16px;
      width: 16px;
      background: ${(props) => props.theme.color.background};
      border: 1px solid ${(props) => props.theme.color.primaryBase};
      position: absolute;
      top: 1px;
      left: -5px;
      border-radius: 4px;
      -moz-transform: rotate(45deg) translateZ(-1px);
      -o-transform: rotate(45deg) translateZ(-1px);
      -ms-transform: rotate(45deg) translateZ(-1px);
      -webkit-transform: rotate(45deg) translateZ(-1px);
      transform: rotate(45deg) translateZ(-1px);
    }
  }

  .browsing-card-item-content {
    margin-left: 5px;
    line-height: 1.5;
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  }

  .browsing-card-instalment {
    font-size: 12px;
    line-height: 20px;
  }

  .secondary-price-my {
    margin-left: 8px;
  }

  .secondary-price-th {
    margin-left: 2px;
  }

  .vatPercent {
    font-size: 8px;
  }

  .browsing-card-listed-price {
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-size: 10px;
    line-height: 14px;
  }
`;

const BadgeContainer = styled.div``;

const StyledCardInfo = styled.div`
  align-items: center;
  ${(props) => props.theme.typo.familyGoogle.regular};
  border: solid 0.5px ${(props) => props.theme.color.onBackgroundLowEmphasis};
  border-radius: 12px;
  background-color: ${(props) => props.theme.color.surfaceMediumEmphasis};
  padding: 8px;
  margin-top: 8px;
  font-size: 10px;
  @media screen and (min-width: 363px) {
    font-size: 12px;
  }
  line-height: 20px;
  .icon {
    width: 12px;
    height: 12px;
  }

  .secondRow {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    flex-wrap: wrap;
  }
`;

const StyledList = styled.div`
  display: flex;
  align-items: center;
  gap: 4px 20px;
  flex-wrap: wrap;
`;

const StyledDividerLine = styled.span`
  height: 12px;
  width: 1px;
  background-color: black;
  margin-left: 8px;
  margin-right: 4px;
`;

const StyledMYSecondaryDetails = styled.div<ISecondaryDetailsMY>`
  display: ${(props) => (props.isGrid ? 'grid' : 'flex')};
  grid-template-columns: 2fr auto auto;
  align-items: center;

  @media screen and (min-width: 576px) {
    display: flex;
  }
`;

export const StyledCarPricePopover = styled(Popover)`
  .infoIcon {
    cursor: pointer;
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  }
`;

export const StyledInfoIcon = styled(InfoCircleOutlined)`
  font-size: 18px;
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  margin-left: 4px;
`;

interface PrimaryValueProps {
  fontSize: number;
}

const StyledPrimaryValue = styled.span<PrimaryValueProps>`
  font-size: ${(props) => props.fontSize};
  font-weight: 700;
  line-height: 32px;
  ${(props) => props.theme.typo.familyGoogle.bold};
`;

const StyledPopover = styled(Popover)`
  margin: 4px;
  font-size: 16px;
`;

const StyledCertifiedFlag = styled.div`
  font-size: 10px;
  background: #ff4c00;
  padding: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  justify-content: center;
  line-height: 7px;

  .text-bold {
    font-weight: 600;
    margin-right: 2px;
  }
`;

const StyledCertifiedLiteFlag = styled.div`
  font-size: 10px;
  color: #ff4c00;
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  line-height: 7px;
  background: #ffe4d9;

  .text-bold {
    font-weight: 600;
    margin-right: 2px;
  }
`;

const ListItem: FC<{ children: ReactNode }> = ({ children }) => <div className="eclipse">{children}</div>;

const BrowsingCard: FC<BrowsingCardProps> = ({
  isImagePreload,
  car,
  onPhotoClick,
  onPhotoChange,
  region,
  href,
  wrapperStyles,
  hasLazyLoadImageEffect,
  photoSwiperOptions,
  isExternal = false,
  onOtherOnClickAction,
  language = 'en',
}) => {
  const styledWrapperRef = useRef<HTMLAnchorElement>(null);
  const { t } = useTranslation();

  const isMobile = useMobileView(576);

  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();
  const carData = formatCarInfo(car);
  const {
    isPromo,
    flags,
    isSold,
    isPendingSale,
    isComingSoon,
    promoLabel,
    isNewArrival,
    promotion,
    isCarroCertifiedLite,
  } = carData || {};
  const { is_pending_sale: isPendingSaleFlag, is_sold: isSoldFlag, is_coming_soon: isComingSoonFlag } = flags || {};
  const configs = car && (getBrowsingCardConfigs[region || 'sg'] || getBrowsingCardConfigs.sg)(carData);
  const promotionImage = promotion?.[language]?.promo_blurb?.url || promotion?.en?.promo_blurb?.url;
  const hasNotifyMeButton = isPendingSale || isSold || isPendingSaleFlag || isSoldFlag;
  const isDisablePromoLabel =
    isPendingSale || isSold || isPendingSaleFlag || isSoldFlag || isComingSoonFlag || isComingSoon;
  const disablePhotoCarousel = isSold || isSoldFlag; // disable image gallery if the car is sold

  // eslint-disable-next-line no-nested-ternary
  const carPhotos = disablePhotoCarousel ? (carData?.photos?.[0] ? [carData.photos[0]] : []) : carData?.photos; // only show the first image if the car is sold
  const carPlaceholderImg = 'carro-webs/assets/shared/icons/car-missing-image.png';

  const formattedFlags = {
    sold: isSold || isSoldFlag || false,
    pendingSale: isPendingSale || isPendingSaleFlag || false,
    comingSoon: isComingSoon || isComingSoonFlag || false,
  };

  const showNewArrivalTag =
    isNewArrival &&
    !formattedFlags.sold &&
    !formattedFlags.pendingSale &&
    !formattedFlags.comingSoon &&
    !promoLabel &&
    !isPromo;

  const currency = mappingCountryCurrency[region as CountryCode];
  const getCCPOPopoverContent = () => {
    if (countryCode === 'jp') {
      return (
        <CarroCertifiedPopoverContent
          content={{
            detailContentTranslationKey: [
              'shared.certifiedModal.detail.jp.inspection',
              'shared.certifiedModal.detail.jp.guarantee',
              'shared.certifiedModal.detail.jp.warranty',
              'shared.certifiedModal.detail.jp.comprehensive',
              'shared.certifiedModal.detail.jp.noMajor',
            ],
          }}
        />
      );
    }

    if (countryCode === 'hk') {
      return (
        <CarroCertifiedPopoverContent
          content={{
            detailContentTranslationKey: [
              'shared.certifiedModal.detail.inspection',
              'shared.certifiedModal.detail.returnPolicy',
              'shared.certifiedModal.detail.warranty',
              'shared.certifiedModal.detail.noMileage',
              'shared.certifiedModal.detail.noMajor',
            ],
          }}
        />
      );
    }

    return <CarroCertifiedPopoverContent />;
  };

  const renderCertifiedTag = () => (
    <Popover placement="bottom" overlayClassName="ccpo-popover" content={getCCPOPopoverContent()}>
      {['jp', 'hk'].includes(region) ? (
        <StyledCertifiedFlag>
          <span className="text-bold">{t('shared.listingCard.carro')}</span>
          <span>{t('shared.listingCard.certified')}</span>
        </StyledCertifiedFlag>
      ) : (
        <img alt="tag" src={configs.carroCertifiedUrl} width={78} height={17} />
      )}
    </Popover>
  );

  const getCCPOLitePopoverContent = () => {
    if (countryCode === 'jp') {
      return (
        <CarroCertifiedLitePopoverContent
          content={{
            detailContentTranslationKey: [
              'shared.certifiedLiteModal.detail.jp.inspection',
              'shared.certifiedLiteModal.detail.jp.guarantee',
              'shared.certifiedLiteModal.detail.jp.warranty',
              'shared.certifiedLiteModal.detail.jp.comprehensive',
              'shared.certifiedLiteModal.detail.jp.noMajor',
            ],
          }}
        />
      );
    }

    if (countryCode === 'hk') {
      return (
        <CarroCertifiedLitePopoverContent
          content={{
            detailContentTranslationKey: [
              'shared.certifiedLiteModal.detail.inspection',
              'shared.certifiedLiteModal.detail.returnPolicy',
              'shared.certifiedLiteModal.detail.warrantyHK',
              'shared.certifiedLiteModal.detail.noMileage',
              'shared.certifiedLiteModal.detail.noMajor',
            ],
          }}
        />
      );
    }

    return <CarroCertifiedLitePopoverContent />;
  };

  const renderCertifiedLiteTag = () => (
    <Popover placement="bottom" overlayClassName="ccpo-lite-popover" content={getCCPOLitePopoverContent()}>
      <StyledCertifiedLiteFlag>
        <span className="text-bold" style={{ fontFamily: 'Poppins' }}>
          {t('shared.listingCard.carro')}
        </span>
        <Trans
          defaults="shared.listingCard.certifiedLite"
          components={{ span: <span />, specificFont: <span style={{ fontFamily: 'Poppins' }} /> }}
        />
        {/* <span>{t('shared.listingCard.certifiedLite')}</span> */}
      </StyledCertifiedLiteFlag>
    </Popover>
  );

  const renderPriceInfo = () => {
    if (isMobile) {
      return <PriceInfoModal content={() => PriceInfoContent({ data: configs.pricePopover, bottomText: true })} />;
    }

    return (
      <StyledPopover
        overlayClassName="pricePopover"
        overlayInnerStyle={{ borderRadius: '30px' }}
        placement="bottom"
        content={() => PriceInfoContent({ data: configs.pricePopover })}
      >
        <InfoCircleOutlined className="browsing-card-info-icon" />
      </StyledPopover>
    );
  };

  const renderPriceInstalment = () => {
    if (region === 'my') {
      return (
        <StyledMYSecondaryDetails isGrid={language === 'ms' && configs?.discountValue?.length > 5}>
          {configs.secondaryValue && (
            <Typography className="browsing-card-instalment" variant="label">
              {configs.secondaryValue}
            </Typography>
          )}
          {configs.primarySubValue && (
            <Typography className="browsing-card-original-price secondary-price-my" variant="label">
              {currency}
              {configs.primarySubValue}
            </Typography>
          )}
          {configs.discountValue && (
            <Typography className="browsing-card-discount secondary-price-my" variant="label">
              -{currency}
              {configs.discountValue}
            </Typography>
          )}
        </StyledMYSecondaryDetails>
      );
    }

    if (region === 'th') {
      return (
        <>
          <StyledMYSecondaryDetails isGrid={language === 'ms' && configs?.discountValue?.length > 5}>
            {configs.secondaryValue && (
              <>
                <Typography className="browsing-card-instalment " variant="body">
                  {t('shared.listingCard.priceInfo.price')}:
                </Typography>
                <Typography className="browsing-card-instalment secondary-price-th" variant="smallButtonOrLink">
                  {configs.secondaryValue}
                </Typography>
              </>
            )}
            {configs.secondaryNote && (
              <Typography className="secondary-price-th" variant="label">
                {configs.secondaryNote}
              </Typography>
            )}
            {configs.primarySubValue && (
              <Typography
                className="browsing-card-original-price browsing-card-original-price-th secondary-price-th"
                variant="label"
              >
                {configs.primarySubValue}
              </Typography>
            )}
            {configs.discountValue && (
              <Typography className="browsing-card-discount" variant="label">
                -{configs.discountValue}
              </Typography>
            )}
          </StyledMYSecondaryDetails>

          <StyledMYSecondaryDetails isGrid={language === 'ms' && configs?.discountValue?.length > 5}>
            {configs.priceWithGst && (
              <Typography className="priceWithGst priceWithGstTH" variant="label">
                {t('shared.listingCard.priceInfo.nett')}: {configs.priceWithGst}
              </Typography>
            )}
            {configs.vatPercent && (
              <Typography className="priceWithGst priceWithGstTH secondary-price-th vatPercent">
                {configs.vatPercent || ''}
              </Typography>
            )}
            {configs.primarySubValue && (
              <Typography
                className="browsing-card-original-price browsing-card-original-price-th secondary-price-th"
                variant="label"
              >
                {configs.primarySubValue}
              </Typography>
            )}
          </StyledMYSecondaryDetails>
        </>
      );
    }

    return (
      <div className={`browsing-card-secondary-details ${region}`}>
        {configs.secondaryValue && (
          <Typography className="browsing-card-instalment" variant="label">
            {configs.secondaryValue}
          </Typography>
        )}
        {configs.pricePopover && renderPriceInfo()}
      </div>
    );
  };

  const renderCarPricePopover = ({ popoverId, ...popoverContent }: { popoverId: string; [key: string]: any }) => {
    if (popoverId === 'totalPayment') {
      const { listedPrice, allInPrice } = popoverContent || {};

      const content = () => (
        <div style={{ padding: isMobile ? '20px' : '' }}>
          <TotalPaymentPopoverContent
            listedPrice={listedPrice}
            allInPrice={allInPrice}
            content={{
              title: t('shared.listingCard.carPricePopover.title'),
              desc: t('shared.listingCard.carPricePopover.desc'),
              listedPriceLabel: t('shared.listingCard.carPricePopover.listedPriceLabel'),
              allInPriceLabel: t('shared.listingCard.carPricePopover.allInPriceLabel'),
            }}
          />
        </div>
      );

      if (isMobile) {
        return <PriceInfoModal content={() => content()} />;
      }

      return (
        <StyledCarPricePopover
          overlayInnerStyle={{ borderRadius: '30px', width: '311px', padding: '8px 4px' }}
          placement="bottom"
          content={content}
        >
          <StyledInfoIcon />
        </StyledCarPricePopover>
      );
    }

    return null;
  };

  const renderCarDetails = (
    <>
      <div className="browsing-card-price-details">
        <div className={`browsing-card-price ${region === 'th' ? 'th' : ''}`}>
          {configs.allInPrice && ['jp', 'hk'].includes(region) ? (
            <>
              <Trans
                defaults={t(`shared.listingCard.priceCarCard.${countryCode}`)}
                components={{
                  span: <span className="browsing-card-currency" />,
                  styled: <StyledPrimaryValue fontSize={configs.primaryValue.fontSize} />,
                }}
                values={{ currency, price: configs.allInPrice }}
              />
              {configs.carPricePopover && renderCarPricePopover(configs.carPricePopover)}
            </>
          ) : (
            <Trans
              defaults={t(`shared.listingCard.priceCarCard.${countryCode}`)}
              components={{
                span: <span className="browsing-card-currency" />,
                styled: <StyledPrimaryValue fontSize={configs.primaryValue.fontSize} />,
              }}
              values={{ currency, price: configs.primaryValue.value }}
            />
          )}
        </div>
        {configs.pricePopover && (region === 'my' || region === 'th') && renderPriceInfo()}
        {configs.primarySubValue && region !== 'my' && region !== 'th' && (
          <Typography className="browsing-card-original-price" variant="label">
            {configs.primarySubValue}
          </Typography>
        )}
        {configs.discountValue && region !== 'my' && region !== 'th' && (
          <Typography className="browsing-card-discount" variant="label">
            -{configs.discountValue}
          </Typography>
        )}
      </div>
      {configs.allInPrice && configs.listedPrice && (
        <div className="browsing-card-listed-price">
          {configs.listedPrice}
          <span>{` (${t('shared.listingCard.listedPrice')})`}</span>
        </div>
      )}

      {renderPriceInstalment()}

      <h2 className="browsing-card-title">{carData?.title}</h2>

      <StyledCardInfo>
        {configs?.carInfoFirstRow && (
          <StyledList>
            {Object.keys(configs?.carInfoFirstRow)?.map((key) => {
              const data = configs?.carInfoFirstRow?.[key];
              return (
                <ListItem key={key}>
                  {data?.icon && <Image src={data?.icon} className="icon" />}
                  {data?.iconUrl && (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img src={data?.iconUrl} alt={`${key}-icon`} className="icon" />
                  )}
                  <span className="browsing-card-item-content">{data?.value}</span>
                </ListItem>
              );
            })}
          </StyledList>
        )}

        {configs?.carInfoSecondRow && !!Object.keys(configs?.carInfoSecondRow).length && (
          <div className="secondRow">
            <StyledList>
              {Object.keys(configs?.carInfoSecondRow)?.map((key, index) => {
                const data = configs?.carInfoSecondRow?.[key];
                const isLastItem = index === (Object.keys(configs?.carInfoSecondRow).length || 0) - 1;

                return (
                  <ListItem key={key}>
                    {data?.icon && <Image src={data?.icon} className="icon" />}
                    {data?.iconUrl && (
                      // eslint-disable-next-line @next/next/no-img-element
                      <img src={data?.iconUrl} alt={`${key}-icon`} className="icon" />
                    )}
                    <span className="browsing-card-item-content">{data?.value}</span>
                    {data?.subValue && <span className="browsing-card-remaining-date">{data?.subValue}</span>}
                    {(!isLastItem || carData?.isCarroCertified || carData?.isCarroCertifiedLite) && (
                      <StyledDividerLine />
                    )}
                  </ListItem>
                );
              })}
            </StyledList>
            {carData?.isCarroCertified && !carData?.isCarroCertifiedLite && renderCertifiedTag()}
            {carData?.isCarroCertifiedLite && renderCertifiedLiteTag()}
          </div>
        )}
      </StyledCardInfo>
    </>
  );

  return (
    <StyledWrapper
      ref={styledWrapperRef}
      href={href}
      wrapperStyles={wrapperStyles}
      target={isExternal ? '_blank' : '_self'}
    >
      {showNewArrivalTag && <StyledTag src={NEW_ARRIVAL_TAG} alt="new-arrival" />}

      <StyledPhotoWrapper>
        <div className="photo-carousel">
          <PhotoCarousel
            id={carData.id}
            hasNotifyMeButton={hasNotifyMeButton}
            isImagePreload={isImagePreload}
            onPhotoChange={onPhotoChange}
            isExternal={isExternal}
            onOtherOnClickAction={onOtherOnClickAction}
            onPhotoClick={onPhotoClick}
            photos={carPhotos}
            carPlaceholderImg={carPlaceholderImg}
            alt={carData?.title}
            hasLazyLoadImageEffect={hasLazyLoadImageEffect}
            photoSwiperOptions={photoSwiperOptions}
          />
        </div>
        <StatusRibbon hasPromo={isPromo as boolean} flags={formattedFlags} />
      </StyledPhotoWrapper>
      <PromoLabel
        promoLabel={promoLabel}
        isDisablePromoLabel={isDisablePromoLabel}
        isPromo={isPromo as boolean}
        promoImg={promotionImage}
      />
      <StyledCardContentWrapper region={region}>
        {renderCarDetails}
        <BadgeContainer>
          <FeatureBadge
            featureFlags={configs?.featureFlags}
            carTitle={carData?.title}
            href={href}
            agentWarranty={carData?.agentWarranty}
            insideBadges={carData?.insideBadges}
          />
        </BadgeContainer>
      </StyledCardContentWrapper>
    </StyledWrapper>
  );
};

BrowsingCard.defaultProps = {
  isExternal: false,
  onOtherOnClickAction: undefined,
};

export default BrowsingCard;
